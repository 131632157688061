var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "more-actions--table" },
    [
      _c("mew-table", {
        staticClass: "d-none d-lg-block",
        attrs: {
          "table-headers": _vm.mewTableHeader,
          "table-data": _vm.mewTableData,
        },
      }),
      _c(
        "div",
        { staticClass: "d-block d-lg-none px-2 mb-n2" },
        [
          _c("mobile-table", {
            staticClass: "my-3",
            attrs: {
              button: "Trade",
              token: "XMR",
              "token-value": "27.54",
              "table-data": _vm.mobileTable,
            },
          }),
          _c("mobile-table", {
            staticClass: "my-3",
            attrs: {
              button: "Trade",
              token: "XMR",
              "token-value": "27.54",
              "table-data": _vm.mobileTable,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }