<template>
  <div class="more-actions--table">
    <mew-table
      class="d-none d-lg-block"
      :table-headers="mewTableHeader"
      :table-data="mewTableData"
    >
    </mew-table>

    <div class="d-block d-lg-none px-2 mb-n2">
      <mobile-table
        class="my-3"
        button="Trade"
        token="XMR"
        token-value="27.54"
        :table-data="mobileTable"
      />
      <mobile-table
        class="my-3"
        button="Trade"
        token="XMR"
        token-value="27.54"
        :table-data="mobileTable"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    mobileTable: () => import('@/components/mobile-table/MobileTable')
  },
  data: () => ({
    mobileTable: [
      { label: 'Price', value: '$8.34' },
      { label: 'Market Cap', value: '$1.53B' },
      { label: '24h Changes', value: '0.25%' },
      { label: 'Token Value', value: '$228.34' }
    ],
    mewTableHeader: [
      {
        text: 'Token name',
        value: 'token',
        sortable: true,
        width: '100%'
      },
      {
        text: 'Price',
        value: 'price',
        sortable: true,
        width: '100%'
      },
      {
        text: 'Market cap',
        value: 'cap',
        sortable: true,
        width: '100%'
      },
      {
        text: 'Change(24H)',
        value: 'change',
        sortable: true,
        width: '100%'
      },
      {
        text: 'Amount',
        value: 'amount',
        sortable: true,
        width: '100%'
      },
      {
        text: 'My value',
        value: 'myValue',
        sortable: true,
        width: '100%'
      }
    ],

    mewTableData: [
      {
        token: 'Monero(XMR1)',
        price: '$8.23',
        cap: '$1.23B',
        change: '2.23%',
        status: '+',
        changeData: {
          x: [1, 4, 10, 4],
          y: [0, 1, 34, 43]
        },
        amount: '27.54',
        myValue: '$227.54'
      },
      {
        token: 'Monero(XMR2)',
        price: '$8.23',
        cap: '$1.23B',
        change: '2.23%',
        status: '+',
        changeData: {
          x: [1, 4, 10, 4],
          y: [0, 1, 34, 43]
        },
        amount: '27.54',
        myValue: '$227.54'
      },
      {
        token: 'Monero(XMR3)',
        price: '$8.23',
        cap: '$1.23B',
        change: '2.23%',
        status: '+',
        changeData: {
          x: [1, 4, 10, 4],
          y: [0, 1, 34, 43]
        },
        amount: '27.54',
        myValue: '$227.54'
      },
      {
        token: 'Monero(XMR4)',
        price: '$8.23',
        cap: '$1.23B',
        change: '2.23%',
        status: '+',
        changeData: {
          x: [1, 4, 10, 4],
          y: [0, 1, 34, 43]
        },
        amount: '27.54',
        myValue: '$227.54'
      },
      {
        token: 'Monero(XMR5)',
        price: '$8.23',
        cap: '$1.23B',
        change: '2.23%',
        status: '+',
        changeData: {
          x: [1, 4, 10, 4],
          y: [0, 1, 34, 43]
        },
        amount: '27.54',
        myValue: '$227.54'
      }
    ],
    addresses: []
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.addresses = [
        {
          icon: require('@/assets/images/currencies/icon-btc-gold.svg'),
          token: 'Monero(XMR)',
          price: '$8.29',
          marketCap: '$1.56B',
          change: 0.23,
          chart: [
            [1167609600000, 0.7137],
            [1167696000000, 0.7537],
            [1167782400000, 0.7559],
            [1167868800000, 0.7631],
            [1167955200000, 0.7644],
            [1168214400000, 0.769],
            [1168300800000, 0.7683],
            [1168387200000, 0.77],
            [1168473600000, 0.7703],
            [1168560000000, 0.7757],
            [1168819200000, 0.7728],
            [1168905600000, 0.7721],
            [1168992000000, 0.7748],
            [1169078400000, 0.774],
            [1169164800000, 0.7718],
            [1169424000000, 0.7731],
            [1169510400000, 0.767],
            [1169596800000, 0.769],
            [1169683200000, 0.7706],
            [1169769600000, 0.7752],
            [1170028800000, 0.774],
            [1170115200000, 0.771],
            [1170201600000, 0.7721],
            [1170288000000, 0.7681],
            [1170374400000, 0.7681],
            [1170633600000, 0.7738],
            [1170720000000, 0.772],
            [1170806400000, 0.7701],
            [1170892800000, 0.7699],
            [1170979200000, 0.7689],
            [1171238400000, 0.7719],
            [1171324800000, 0.768],
            [1171411200000, 0.7645],
            [1171497600000, 0.7613],
            [1171584000000, 0.7624],
            [1171843200000, 0.7616],
            [1171929600000, 0.7608],
            [1172016000000, 0.7608],
            [1172102400000, 0.7931]
          ],
          amount: 27.54,
          myvalue: '$228.42'
        },
        {
          icon: require('@/assets/images/currencies/icon-eth-blue.svg'),
          token: 'AMIS(AMIS)',
          price: '$10.72',
          marketCap: '$1.56B',
          change: 0.23,
          chart: [
            [1167609600000, 0.7137],
            [1167696000000, 0.7537],
            [1167782400000, 0.7559],
            [1167868800000, 0.7631],
            [1167955200000, 0.7644],
            [1168214400000, 0.769],
            [1168300800000, 0.7683],
            [1168387200000, 0.77],
            [1168473600000, 0.7703],
            [1168560000000, 0.7757],
            [1168819200000, 0.7728],
            [1168905600000, 0.7721],
            [1168992000000, 0.7748],
            [1169078400000, 0.774],
            [1169164800000, 0.7718],
            [1169424000000, 0.7731],
            [1169510400000, 0.767],
            [1169596800000, 0.769],
            [1169683200000, 0.7706],
            [1169769600000, 0.7752],
            [1170028800000, 0.774],
            [1170115200000, 0.771],
            [1170201600000, 0.7721],
            [1170288000000, 0.7681],
            [1170374400000, 0.7681],
            [1170633600000, 0.7738],
            [1170720000000, 0.772],
            [1170806400000, 0.7701],
            [1170892800000, 0.7699],
            [1170979200000, 0.7689],
            [1171238400000, 0.7719],
            [1171324800000, 0.768],
            [1171411200000, 0.7645],
            [1171497600000, 0.7613],
            [1171584000000, 0.7624],
            [1171843200000, 0.7616],
            [1171929600000, 0.7608],
            [1172016000000, 0.7608],
            [1172102400000, 0.7931]
          ],
          amount: 27.54,
          myvalue: '$228.42'
        },
        {
          icon: require('@/assets/images/currencies/icon-eth-blue.svg'),
          token: 'Joack(JCK)',
          price: '$0.21',
          marketCap: '$1.56B',
          change: 0.23,
          chart: [
            [1167609600000, 0.7137],
            [1167696000000, 0.7537],
            [1167782400000, 0.7559],
            [1167868800000, 0.7631],
            [1167955200000, 0.7644],
            [1168214400000, 0.769],
            [1168300800000, 0.7683],
            [1168387200000, 0.77],
            [1168473600000, 0.7703],
            [1168560000000, 0.7757],
            [1168819200000, 0.7728],
            [1168905600000, 0.7721],
            [1168992000000, 0.7748],
            [1169078400000, 0.774],
            [1169164800000, 0.7718],
            [1169424000000, 0.7731],
            [1169510400000, 0.767],
            [1169596800000, 0.769],
            [1169683200000, 0.7706],
            [1169769600000, 0.7752],
            [1170028800000, 0.774],
            [1170115200000, 0.771],
            [1170201600000, 0.7721],
            [1170288000000, 0.7681],
            [1170374400000, 0.7681],
            [1170633600000, 0.7738],
            [1170720000000, 0.772],
            [1170806400000, 0.7701],
            [1170892800000, 0.7699],
            [1170979200000, 0.7689],
            [1171238400000, 0.7719],
            [1171324800000, 0.768],
            [1171411200000, 0.7645],
            [1171497600000, 0.7613],
            [1171584000000, 0.7624],
            [1171843200000, 0.7616],
            [1171929600000, 0.7608],
            [1172016000000, 0.7608],
            [1172102400000, 0.7931]
          ],
          amount: 27.54,
          myvalue: '$228.42'
        },
        {
          icon: require('@/assets/images/currencies/icon-btc-gold.svg'),
          token: 'Amon(AMN)',
          price: '$10.22',
          marketCap: '$1.56B',
          change: 0.23,
          chart: [
            [1167609600000, 0.7137],
            [1167696000000, 0.7537],
            [1167782400000, 0.7559],
            [1167868800000, 0.7631],
            [1167955200000, 0.7644],
            [1168214400000, 0.769],
            [1168300800000, 0.7683],
            [1168387200000, 0.77],
            [1168473600000, 0.7703],
            [1168560000000, 0.7757],
            [1168819200000, 0.7728],
            [1168905600000, 0.7721],
            [1168992000000, 0.7748],
            [1169078400000, 0.774],
            [1169164800000, 0.7718],
            [1169424000000, 0.7731],
            [1169510400000, 0.767],
            [1169596800000, 0.769],
            [1169683200000, 0.7706],
            [1169769600000, 0.7752],
            [1170028800000, 0.774],
            [1170115200000, 0.771],
            [1170201600000, 0.7721],
            [1170288000000, 0.7681],
            [1170374400000, 0.7681],
            [1170633600000, 0.7738],
            [1170720000000, 0.772],
            [1170806400000, 0.7701],
            [1170892800000, 0.7699],
            [1170979200000, 0.7689],
            [1171238400000, 0.7719],
            [1171324800000, 0.768],
            [1171411200000, 0.7645],
            [1171497600000, 0.7613],
            [1171584000000, 0.7624],
            [1171843200000, 0.7616],
            [1171929600000, 0.7608],
            [1172016000000, 0.7608],
            [1172102400000, 0.7931]
          ],
          amount: 27.54,
          myvalue: '$228.42'
        }
      ];
    }
  }
};
</script>

<style lang="scss">
.more-actions--table {
  .v-data-table-header {
    background-color: var(--v-table-header-base);
  }
  .v-data-table thead tr th {
    text-transform: uppercase;
    font-weight: 500;
    &:not(:hover) {
      color: var(--v-greyPrimary-base) !important;
    }
  }
}
</style>
